<template>
  <div style="padding: 20px">
    <img src="@/assets/kaercher_logo.svg" />
    <h2>Оберіть місто</h2>
    <v-select v-model="selectedCity" label="name" :filterable="false" :options="optionsCity" @search="onSearchCity">
      <template v-slot:no-options>
        наберіть назву міста
      </template>
      <template v-slot:option="option">
        <div class="d-center">
          {{ option.Present }}
        </div>
      </template>
      <template v-slot:selected-option="option">
        <div class="selected d-center">
          {{ option.Present }}
        </div>
      </template>
    </v-select>
    <h2>Оберіть вулицю</h2>
    <v-select v-model="selectedStreet" label="name" :filterable="false" :options="optionsStreet"
              @search="onSearchStreet" :disabled="emptyCity">
      <template v-slot:no-options>
        наберіть назву вулиці
      </template>
      <template v-slot:option="option">
        <div class="d-center">
          {{ option.Present }}
        </div>
      </template>
      <template v-slot:selected-option="option">
        <div class="selected d-center">
          {{ option.Present }}
        </div>
      </template>
    </v-select>
    <div v-if="selectedStreet && selectedStreet.SettlementStreetDescription" style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 20px">
      <div style="font-size: 20px">Назва вулиці:</div>
      <div style="font-size: 20px"><b>{{ selectedStreet.SettlementStreetDescription }}</b></div>
      <button class="button-37" style="margin-top: 30px" @click="copyToClipboard">Скопіювати назву вулиці</button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import _ from 'lodash'
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: 'App',
  components: {
    vSelect,
  },
  data() {
    return {
      optionsCity: [],
      selectedCity: null,
      optionsStreet: [],
      selectedStreet: null
    }
  },
  computed: {
    emptyCity() {
      return _.isEmpty(this.selectedCity)
    }
  },
  methods: {
    url() {
      let Production_url = window.location.origin + '/';
      let Development_url = 'http://localhost:8081/' //'http://localhost:8095/api/';
      if (process.env.NODE_ENV === 'production') return Production_url;
      else {
        return Development_url;
      }
    },
    onSearchCity(search, loading) {
      if (search.length) {
        loading(true);
        this.selectedCity = null;
        this.searchCity(loading, search, this);
      }
    },
    searchCity: _.debounce(async (loading, search, vm) => {
      let requestObj = {
        "city": search
      }
      try {
        await fetch(vm.url() + "city", {
              method: "POST",
              body: JSON.stringify(requestObj),
            }
        ).then(res => {
          res.json().then(json => {
            let parsedJSON = JSON.parse(json)
            if (parsedJSON.success === true) {
              vm.optionsCity = parsedJSON.data[0].Addresses
            } else {
              if (parsedJSON.errors[0] === 'CityName has invalid characters') {
                toast.error("У назві міста є недопустимі символи", {
                  timeout: 3000,
                  position: "top-center",
                });
              } else {
                toast.error(parsedJSON.errors[0], {
                  timeout: 3000,
                  position: "top-center",
                });
              }
            }

          });
          loading(false);
        });
      } catch (error) {
        toast.error(error, {
          timeout: 3000,
          position: "top-center",
        });
      }

    }, 300),
    onSearchStreet(search, loading) {
      if (search.length) {
        loading(true);
        this.selectedStreet = null;
        this.searchStreet(loading, search, this);
      }
    },
    searchStreet: _.debounce(async (loading, search, vm) => {
      let requestObj = {
        "street": search,
        "SettlementRef": vm.selectedCity.Ref
      }
      try {
        await fetch(vm.url() + "street", {
              method: "POST",
              body: JSON.stringify(requestObj),
            }
        ).then(res => {
          res.json().then(json => {
            let parsedJSON = JSON.parse(json)
            if (parsedJSON.success === true) {
              vm.optionsStreet = parsedJSON.data[0].Addresses
            } else {
              if (parsedJSON.errors[0] === 'StreetName has invalid characters') {
                toast.error("У назві вулиці є недопустимі символи", {
                  timeout: 3000,
                  position: "top-center",
                });
              } else {
                toast.error(parsedJSON.errors[0], {
                  timeout: 3000,
                  position: "top-center",
                });
              }
            }

          });
          loading(false);
        });
      } catch (error) {
        toast.error(error, {
          timeout: 3000,
          position: "top-center",
        });
      }

    }, 300),
    copyToClipboard() {
      if (this.selectedStreet && this.selectedStreet.SettlementStreetDescription)
      navigator.clipboard.writeText(this.selectedStreet.SettlementStreetDescription);
      toast.success("Скопійовано успішно", {
        timeout: 3000,
        position: "top-center",
      });
    }
  },
}
</script>

<style>
.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.button-37 {
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 10px 30px;
  }
}
</style>
